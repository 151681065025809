import React from "react";
import { Form, Container, List, Stack, Text } from "basis";
import { StyledPre, Icon, Flex } from "../../components";

import { InfoCard, Scene } from "../../layout/playground";
import { Configuration, Preview } from "../../layout/playground/checkout";

const Widget = () => {
  const initialValues = {
    currency: "AUD",
    environment: "test",
    merchantId: "[YOUR_MERCHANT_ID]",
  };

  const environmentAppURL = {
    test:
      "https://develop.checkout.dev.merchant-services-np.lfscnp.com/assets/content.js",
    prod: "https://checkout.latitudefinancial.com/assets/content.js",
  };

  return (
    <Form initialValues={initialValues}>
      {({ state }) => (
        <Scene>
          <Scene.Item>
            <InfoCard
              title="Checkout playground"
              description="Instructions on how to install the checkout "
            >
              <Preview
                merchant={{
                  currency: state.values.currency,
                  environment: state.values.environment,
                  merchantId: state.values.merchantId,
                }}
              />
              <Configuration />
            </InfoCard>
          </Scene.Item>

          <Scene.Item>
            <Container bg="white">
              <Container bg="secondary.lightBlue.t15" padding="4 6">
                <Stack direction="horizontal" margin="4 0">
                  <Icon name="assistance" color="black" />

                  <Flex height="100%" placeItems="center">
                    <Text textStyle="subtitle2">
                      <strong>Instructions</strong>
                    </Text>
                  </Flex>
                </Stack>
                <List type="steps">
                  <List.Item>
                    <Container padding="0 0 4 0">
                      <Text>
                        Complete the options on the left hand screen (to
                        dynamically update the script parameters)
                      </Text>
                    </Container>
                  </List.Item>
                  <List.Item>
                    <Container padding="0 0 4 0">
                      <Text>
                        Add the following container where you would like to
                        render the checkout widget.
                      </Text>
                    </Container>
                    <Container bg="white">
                      <StyledPre>
                        {`<div id="main-container"></div>
<div id="footer-container"></div>`}
                      </StyledPre>
                    </Container>
                  </List.Item>
                  <List.Item>
                    <Container padding="0 0 4 0">
                      <Stack gap="4">
                        <Text>
                          Add this script on the page (preferably in the head
                          section) where you would like to show the checkout
                          widget.
                        </Text>
                      </Stack>
                    </Container>

                    <Container bg="white">
                      <StyledPre>{`<script>
window.LatitudeCheckout = {
      "merchantId": "${state.values.merchantId}",
      "page": "checkout",
      "container": {
          "main": "main-container",
          "footer": "footer-container"
      },
      "currency": "${state.values.currency}",
};
 
(function () {
    function asyncLoad() {
        var curr = document.createElement("script");
        curr.type = "text/javascript";
        curr.async = true;
        curr.src = "${
          state.values.environment === "test"
            ? environmentAppURL.test
            : environmentAppURL.prod
        }?platform=direct&merchantId=${state.values.merchantId}";
   
        var scr = document.getElementsByTagName("script")[0];
        scr.parentNode.insertBefore(curr, scr);
    }
   
    if (window.attachEvent) {
        window.attachEvent("onload", asyncLoad);
    } else {
        window.addEventListener("load", asyncLoad, false);
    }
})();
</script>`}</StyledPre>
                    </Container>
                  </List.Item>
                </List>
              </Container>
            </Container>
          </Scene.Item>
        </Scene>
      )}
    </Form>
  );
};

export default Widget;
