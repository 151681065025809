import { CURRENCY } from "../../../src/core/constants";
import { AU, NZ } from "./main/index";

export const Main = ({ merchantId = "", currency = "" }) => {
  switch (currency) {
    case CURRENCY.NZD:
      return NZ(merchantId);

    case CURRENCY.AUD:
      return AU(merchantId);

    default:
      return AU(merchantId);
  }
};
