import { generateURL, LOCATION, URL_TYPE } from "../../../../src/services/url";
import { EVENT_HANDLERS } from "../../../constants";
import { COUNTRY } from "../../../../src/core/constants";

const AU = (merchantId) => {
  const aboutURL = generateURL(
    merchantId,
    COUNTRY.AU,
    URL_TYPE.LEARN_MORE,
    LOCATION.MERCHANT_CHECKOUT_PAGE
  );

  return ` 
  <p style="margin-top: 14px !important; margin-bottom: 14px !important">
  <strong>Enjoy Now. Pay Later.</strong>
  <a
    target="_blank"
    style="color: #0046aa !important; font-size: 12px !important; text-decoration: underline !important;cursor:pointer;" 
    onclick="${EVENT_HANDLERS.LEARN_MORE_CLICK}('${aboutURL}')">Learn more</a>
  </p>

  <p style="margin-top: 14px !important; margin-bottom: 14px !important">
  Flexible Latitude Interest Free plans to suit your needs
  </p>

<div style="display: flex !important; flex-direction: row !important;">
    <img style="height: 65px !important; width: auto;" src="https://assets.latitudefinancial.com/merchant-services/latitude/icon/interest-free-badge.svg" alt="Latitude image left" />
    <img style="height: 65px !important; width: auto;" alt="Latitude image right" src="https://assets.latitudefinancial.com/merchant-services/latitude/icon/shopping.svg" />
</div>

  <p style="margin-top: 14px !important; margin-bottom: 14px !important">
  We’re here to help you get what you need. With plans from 6-24 months, Latitude Gem Visa has a range of Interest Free offers that work for you.
  </p>

  <p style="margin-top: 14px !important; margin-bottom: 14px !important; font-size: 12px">
  You will be redirected to Latitude checkout to complete your order
  </p>
  `;
};

export default AU;
