import { CURRENCY, EVENT_HANDLERS } from "../../constants";
import {
  generateURL,
  LOCATION,
  URL_TYPE,
} from "../../../src/services/url";
import { COUNTRY } from "../../../src/core/constants";

const CONTENT = {
  [CURRENCY.AUD]: {
    title: "Not a Latitude customer ?",
  },
  [CURRENCY.NZD]: {
    title: "Not a Gem Visa customer ?",
  },
};

export const Footer = ({ merchantId = "", currency = CURRENCY.AUD }) => {
  const { title } = CONTENT[currency];

  const country = currency === CURRENCY.NZD ? COUNTRY.NZ : COUNTRY.AU;

  const applyURL = generateURL(merchantId, country, URL_TYPE.APPLY, LOCATION.MERCHANT_CHECKOUT_PAGE);
  const termsURL = generateURL(merchantId, country, URL_TYPE.TERMS, LOCATION.MERCHANT_CHECKOUT_PAGE);

  return `
  <p
  style="margin-top: 14px !important; margin-bottom: 14px !important; font-size: 12px"
  >
    <span>
      ${title}
    </span>
    <a
      style="color: #0046aa !important; font-size: 12px !important; text-decoration: underline !important;cursor:pointer;"
      target="_blank" 
      onclick="${EVENT_HANDLERS.LEARN_MORE_CLICK}('${applyURL}')"
      >
      Apply here
    </a>
  </p>

  <p
  style="margin-top: 14px !important; margin-bottom: 14px !important; font-size: 12px"
  >
    By continuing, you agree to our
      <a
        style="color: #0046aa !important; font-size: 12px !important; text-decoration: underline !important"
        target="_blank"
        href="${termsURL}"
        >
        Terms and conditions
      </a>
  </p>
  `;
};
