import React from "react";
import { Container, RadioGroup, Stack, Input } from "basis";

const currencyOptions = [
  { label: "AUD", value: "AUD" },
  { label: "NZD", value: "NZD" },
];

const environmentOptions = [
  { label: "Test", value: "test" },
  { label: "Production", value: "prod" },
];

export const Configuration = ({ onChange = () => {} }) => {
  return (
    <Container bg="secondary.lightBlue.t15" padding="4 6">
      <Stack gap="4">
        <RadioGroup
          name="currency"
          color="white"
          label="Currency"
          options={currencyOptions}
        />
        <RadioGroup
          name="environment"
          color="white"
          label="Environment"
          options={environmentOptions}
        />
        <Input name="merchantId" color="white" label="Merchant ID" />
      </Stack>
    </Container>
  );
};

export default Configuration;
