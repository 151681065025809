import { generateURL, LOCATION, URL_TYPE } from "../../../../src/services/url";
import { EVENT_HANDLERS } from "../../../constants";
import { COUNTRY } from "../../../../src/core/constants";

const NZ = (merchantId) => {
  const aboutURL = generateURL(
    merchantId,
    COUNTRY.NZ,
    URL_TYPE.LEARN_MORE,
    LOCATION.MERCHANT_CHECKOUT_PAGE
  );

  return `
  <p style="margin-top: 14px !important; margin-bottom: 14px !important">
    <strong>Enjoy Now. Pay Later.</strong>
    <a target="_blank" style="color: #0046aa !important; font-size: 12px !important; text-decoration: underline !important;cursor:pointer;" onclick="${EVENT_HANDLERS.LEARN_MORE_CLICK}('${aboutURL}')">Learn more</a>
  </p>

  <p style="margin-top: 14px !important; margin-bottom: 14px !important; font-size: 12px">
  You will be redirected to Gem checkout to complete your order
  </p>
  `;
};

export default NZ;
