import React from "react";
import { Container, Stack } from "basis";

import { Main, Footer } from "../../../../assets/components/checkout";

export const Preview = ({ merchant = {} }) => (
  <Container bg="white" padding="4 6">
    <Stack gap="4">
      <div
        dangerouslySetInnerHTML={{
          __html: Main({
            layout: merchant?.layout,
            currency: merchant?.currency,
            containerClass: "",
            price: 1000,
            paymentOption: merchant?.paymentOption,
            promotionMonths: merchant?.promotionMonths,
          }),
        }}></div>
      <div
        dangerouslySetInnerHTML={{
          __html: Footer({
            layout: merchant?.layout,
            currency: merchant?.currency,
            containerClass: "",
            price: 1000,
            paymentOption: merchant?.paymentOption,
            promotionMonths: merchant?.promotionMonths,
          }),
        }}></div>
    </Stack>
  </Container>
);

export default Preview;
